
<!--
 * @Description: 
 * @Author: 琢磨先生
 * @Date: 2022-05-09 11:00:33
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2022-06-11 02:59:08
-->

<template>
  <el-dialog v-model="drawer" :title="title" width="600px" :show-close="false">
    <el-form :model="form" ref="form" :rules="rules" label-width="140px">
      <el-form-item label="选择父级" prop="father_id">
        <el-select v-model="form.father_id" placeholder="" clearable>
          <el-option
            v-for="item in groups"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
        <div class="help-block">不选择父级即为最顶级</div>
      </el-form-item>
      <el-form-item label="名称" prop="name">
        <el-col :span="14">
          <el-input v-model="form.name" placeholder="请输入名称"></el-input>
        </el-col>
      </el-form-item>

      <el-form-item label="描述">
        <el-col :span="20">
          <el-input
            v-model="form.description"
            placeholder="请输入描述"
          ></el-input
        ></el-col>
      </el-form-item>

      <el-form-item label="停用" prop="is_stop">
        <el-switch v-model="form.is_stop"> </el-switch>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="onSubmit" :loading="saving"
          >确定</el-button
        >
        <el-button @click="cancelClick" :disabled="saving">取消</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>
<script>
export default {
  data() {
    return {
      drawer: false,
      saving: false,
      title: "编辑",
      form: {},
      rules: {
        name: [
          {
            required: true,
            message: "请输入名称",
            trigger: "blur",
          },
        ],
      },
    };
  },
  props: ["item", "groups", "reload"],
  watch: {
    item: {
      handler() {
        if (this.item) {
          this.drawer = true;
          this.form = Object.assign({}, this.item);
        }
      },
    },
  },

  methods: {
    /**
     * 提交保存
     */
    onSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.saving = true;
          this.$http
            .post("/admin/v1/building/edit", this.form)
            .then((res) => {
              this.saving = false;
              if (res.code == 0) {
                this.drawer = false;
                this.$message({
                  type: "success",
                  message: res.msg,
                });
                if (this.reload) {
                  this.reload();
                }
              }
            })
            .cath(() => {
              this.saving = false;
            });
        }
      });
    },
    /**
     * 取消
     */
    cancelClick() {
      this.drawer = false;
      if (this.reload) {
        this.reload();
      }
    },
  },
};
</script>